
























import { Component, Prop, Watch } from 'vue-property-decorator'

// components
import TextAreaInput from '@/components/_uikit/controls/TextAreaInput.vue'
import NotifyMixin from '@/mixins/NotifyMixin'
import { ManagerLessonLargeMasterResource } from '@/store/types'
import ModalWrapper from '@/components/modals/ModalWrapper.vue'
import UserCard from '@/components/cards/UserCard.vue'
import AuthModule from '@/store/modules/auth'

@Component({
  components: {
    ModalWrapper,
    TextAreaInput,
    UserCard,
  },
})
export default class RatedLessonModal extends NotifyMixin {
  @Prop({ required: true })
  private visible!: boolean

  @Prop({ required: true })
  private masters!: ManagerLessonLargeMasterResource[]

  @Prop({ default: null })
  private currentMaster!: ManagerLessonLargeMasterResource

  private innerCurrentMaster: ManagerLessonLargeMasterResource | null = null
  private currentIndex = 0

  private handleClose () {
    this.$emit('close')
  }

  private mounted() {
    if (this.currentMaster) {
      this.innerCurrentMaster = this.currentMaster
    } else {
      this.innerCurrentMaster = this.masters[0]
    }
  }

  private get isNextButtonShow() {
    return !(this.currentIndex === this.masters.length - 1 || this.masters.length - 1 <= 0)
  }

  private get isPreviousButtonShow() {
    return this.currentIndex !== 0
  }

  private get isLocalTimezone() {
    return AuthModule.isLocalTimezone
  }

  private handlePrev() {
    if (this.masters.length > 0) {
      if (this.currentIndex !== 0) {
        this.currentIndex -= 1
      }
    }
  }

  private handleNext() {
    if (this.masters.length > 0) {
      if (this.currentIndex !== this.masters.length - 1) {
        this.currentIndex += 1
      }
    }
  }

  private get ratingImage() {
    const ratingNames = ['bad', 'ok', 'good']

    return require(`@/assets/images/icons/lesson-${ratingNames[this.innerCurrentMaster?.rating || 0]}.svg`)
  }

  @Watch('visible')
  watchVisibleState(value: boolean) {
    if (value) {
      if (this.currentMaster) {
        this.innerCurrentMaster = this.currentMaster
        this.currentIndex = this.masters.findIndex(item => item.user.id === this.currentMaster.user.id)
      } else {
        this.currentIndex = 0
        this.innerCurrentMaster = this.masters[0]
      }
    }
  }

  @Watch('currentIndex')
  watchCurrentIndex(value: number) {
    this.innerCurrentMaster = this.masters[value]
  }
}
