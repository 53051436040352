





















import { Component, Prop } from 'vue-property-decorator'

// components
import ButtonArrow from '@/components/_uikit/buttons/ButtonArrow.vue'
import StatusIcon from '@/components/StatusIcon.vue'
import Tag from '@/components/_uikit/Tag.vue'
// mixins
import MasterGroupMixin from '@/mixins/MasterGroupMixin'
// store
import { MasterLessonDrawerResource } from '@/store/types'
import AuthModule from '@/store/modules/auth'

@Component({
  components: {
    ButtonArrow,
    StatusIcon,
    Tag,
  },
})
export default class MasterMyLessonCard extends MasterGroupMixin {
  @Prop({ required: true })
  private lesson!: MasterLessonDrawerResource

  private get isChecked () {
    return !!this.lesson.checkedAt
  }

  private get isLocalTimezone() {
    return AuthModule.isLocalTimezone
  }
}
